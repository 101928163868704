import React from 'react'
import Layout from '../components/layout'
import ContactForm from '../components/form'
import { GoogleMap, Marker, LoadScript } from '@react-google-maps/api'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as icons from '@fortawesome/free-solid-svg-icons'

const mapContainerStyle = {
  height: '400px',
}

const position = {
  lat: 41.175815,
  lng: -8.681849,
}

export default function Contactos({ location }) {
  return (
    <Layout location={location} title="Contactos">
      <ContactForm location={location} />
      <div>
        <LoadScript googleMapsApiKey="AIzaSyD92gZisjcwSE1U1pLWw4-NmI8GFB9bnlg">
          <GoogleMap
            mapContainerStyle={mapContainerStyle}
            zoom={15}
            center={position}
          >
            <Marker position={position} />
          </GoogleMap>
        </LoadScript>
        <table className="table-auto">
          <tbody>
            <tr>
              <td className="border px-4 py-2 text-right">
                <FontAwesomeIcon icon={icons.faEnvelope} size="2x" />
              </td>
              <td className="border px-4 py-2">geral@inteliplan.pt</td>
            </tr>
            {/* <tr>
              <td className="border px-4 py-2 text-right">
                <FontAwesomeIcon icon={icons.faPhoneAlt} size="2x" />
              </td>
              <td className="border px-4 py-2">+351 934 589 246</td>
            </tr> */}
            <tr>
              <td className="border px-4 py-2 text-right">
                <FontAwesomeIcon icon={icons.faMapMarkerAlt} size="2x" />
              </td>
              <td className="border px-4 py-2">
                <p>
                  Av. D. Afonso Henriques 1462, Piso 1,
                  <br />
                  4450-013 Matosinhos
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </Layout>
  )
}
