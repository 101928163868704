import React from 'react'

export default function FormLabel({ item }) {
  let input = (
    <input
      className="shadow-lg appearance-none border rounded w-full py-2 px-3 text-one-700 leading-snug focus:outline-none focus:shadow-outline"
      id={item.ref}
      name={item.ref}
      aria-label={item.ref}
      type="text"
    />
  )

  if (item.ref === 'message') {
    input = (
      <textarea
        className="shadow-lg appearance-none border rounded w-full py-2 px-3 text-one-700 leading-snug focus:outline-none focus:shadow-outline h-32"
        id={item.ref}
        name={item.ref}
        aria-label={item.ref}
      />
    )
  }
  return (
    <div className="mb-4">
      <label className="block mb-2" htmlFor={item.ref}>
        {item.name}:{input}
      </label>
    </div>
  )
}
