import React, { useState } from 'react'
import FormLabel from './form-label'
import axios from 'axios'
import Notifications, { notify } from 'react-notify-toast'

export default function ContactForm({ location }) {
  const url = `${location.origin}/api/mail`
  const [isDisabled, setDisabled] = useState(false)

  const makeCall = async ({ event, ...payload }) => {
    try {
      const { data } = await axios.post(url, payload)
      if (data.status === 'failed') throw Error()

      const { name, email, message } = event.target.elements
      name.value = ''
      email.value = ''
      message.value = ''
      notify.show('Mensagem enviada. Obrigado!', 'success')
    } catch (err) {
      notify.show('Não foi possivel enviar a mensagem.', 'error')
    }

    setDisabled(false)
  }

  async function handleSubmit(event) {
    event.preventDefault()
    event.stopPropagation()
    setDisabled(true)
    const { name, email, message } = event.target.elements

    if (
      name.value.length === 0 ||
      email.value.length === 0 ||
      message.value.length === 0
    ) {
      notify.show('Preencha todos os campos por favor!', 'error')
      setDisabled(false)
      return
    }
    makeCall({
      event,
      name: name.value,
      email: email.value,
      message: message.value,
    })
  }

  return (
    <div className="w-full max-w-xs">
      <Notifications />
      <form className="pb-8 mb-4" onSubmit={handleSubmit}>
        <FormLabel item={{ name: 'Nome', ref: 'name' }} />
        <FormLabel item={{ name: 'E-mail', ref: 'email' }} />
        <FormLabel item={{ name: 'Mensagem', ref: 'message' }} />

        <div className="flex items-center justify-between mt-10">
          <button
            disabled={isDisabled}
            className="uppercase bg-red-700 rounded hover:bg-one-700 text-white py-2 px-4 focus:outline-none focus:shadow-outline disabled:opacity-20"
            type="submit"
          >
            Enviar
          </button>
        </div>
      </form>
    </div>
  )
}
